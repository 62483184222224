<template>
    <div :style="{backgroundImage:'url('+bgimg+')','background-size':'100% auto'}">
        <div style="width: 1200px;margin:0 auto;padding-bottom: 30px">
            <div style="color: #999;font-size: 14px;line-height: 50px;">
                当前位置 <span style="margin: 0 10px">/</span> 科技服务 <span style="margin: 0 10px">/</span> <span
                    style="color: #1890FF">高校店铺</span>
            </div>
            <!--            店铺简介 start-->
            <div style="padding: 20px;background: #fff;border-radius: 8px;display: flex;">
                <div>
                    <Avatar v-if="!shopDetal.imageUrl" icon="ios-person" size="70" style="margin: 30px auto"/>
                    <img v-else :src="shopDetal.imageUrl" style="width: 100px;height: 90px" alt="">
                </div>
                <div style="width: 780px;margin-left: 24px;">
                    <div style="font-size: 18px;color: black">{{ shopDetal.name }}</div>
                    <div style="font-size: 16px;color: #999;line-height: 22px;margin-top: 16px;">{{shopDetal.introduction }}
                    </div>
                </div>
                <div style="text-align: center;margin-left: auto;">
                    <div class="zx_btn" @click.stop="openZoosUrl()">立即咨询</div>
                    <div style="margin-top: 10px;color: #576B95;font-size: 12px">点击按钮即可咨询</div>
                </div>
            </div>
            <!--            店铺简介 end-->
            <!--            专利列表 start -->
            <div v-if="patentList.length" style="margin-top: 20px;border-radius: 8px;background: #fff;">
                <div style="height: 60px;line-height: 60px;padding: 0 20px;border-bottom: 1px dashed #E5E8ED;display: flex;">
                    <span style="color: #333;font-size: 24px;font-weight: bold;">店铺专利</span>
                    <img src="~@/assets/image/patent/recommend.png" style="width:68px;height:24px;margin: 18px 10px"
                         alt=""/>
                </div>
                <div class="gd-list">
                    <div v-for="(item,index) in patentList" :key="index" class="per-detail-hover gd-item">
                        <div style="height: 250px" @click="seeDetail(item)">
                            <!--                            <img src="~@/assets/image/patent/patent-default.png" style="height: 200px;width: 230px" alt="">-->
                            <img v-if="item.type == '发明专利'" style="width: 250px;height: 250px;"
                                 src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/famingzhuanli.jpg"/>
                            <img v-else-if="item.type == '实用新型'" style="width: 250px;height: 250px;"
                                 src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/shiyongxinxing.jpg"/>
                            <img v-else style="width: 250px;height: 250px;"
                                 src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/waiguanzhuanli.jpg"/>
                        </div>
                        <div style="height: 160px;">
                            <div style="height: 50px;line-height: 50px;display: flex;justify-content: space-between">
                                <span style="font-size: 20px;color: #FF4040;">暂无报价</span>
                                <span style="color: #999;font-size: 14px;">{{ item.type }}</span>
                            </div>
                            <div style="height: 40px">
                              <span style="font-size: 16px;line-height: 20px;color: black;color: #333;font-weight: bold"
                                    class="liang_hang_sheng">
                                <div v-html="item.ti" class="html-box"></div>
                              </span>
                            </div>
                            <!--                            <div style="font-size: 12px;margin: 10px 0;overflow: hidden;height: 20px">-->
                            <!--                                <div style="float: left"><span>{{ item.ph }}</span></div>-->
                            <!--                                <div style="float: right;color: rgb(255, 106, 0);">{{ item.type }}</div>-->
                            <!--                            </div>-->

                            <div style="margin-top: 14px">
                                <div class="consult" @click="openZoosUrl()">
                                    立即咨询
                                </div>
                                <div class="buybtn" @click.stop="seeDetail(item)">立即购买
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            专利列表 end -->
            <!--分页 start-->
            <div style="height: 70px" v-if="patentList.length>4">
                <Page :total="patenttotalNum" :page-size="shopparams.pageSize" show-elevator
                      style="margin-top: 20px;float: right;" @on-change="changefPage"/>
            </div>
            <!--分页 end-->

            <!--      高校专家 start-->
            <div v-if="professorList.length" style="margin-top:20px;border-radius: 8px;background: #fff;">
                <div style="height: 60px;line-height: 60px;padding: 0 20px;border-bottom: 1px dashed #E5E8ED;display: flex;">
                    <span style="color: #333;font-size: 24px;font-weight: bold;">高校专家</span>
                    <img src="~@/assets/image/patent/recommend.png" style="width:68px;height:24px;margin: 18px 10px"
                         alt=""/>
                </div>
                <div class="pf-list">
                    <div v-for="(item,index) in professorList" :key="index"
                         @click="goExpertShop(item)"
                         class="per-detail-hover pf-item">
                        <div style="display: flex;height: 130px">
                            <Avatar :src="item.imageUrl" icon="ios-person" size="130" style="margin-right: 16px;"/>
                            <div style="margin-left: 5px;display: flex;flex-flow: column;justify-content: center;height: 130px;">
                                <span style="font-size: 18px;color: #333;font-weight: bold;margin-bottom: 8px">{{ item.realName }}</span>
                                <span style="font-size: 14px;color: #333;font-weight: bold;margin-bottom: 8px">{{ item.pa }}</span>
                                <span style="font-size: 14px;color: #999;margin-bottom: 8px">{{ item.direction}}</span>
                                <!--                                <div class="liang_hang_sheng" style="font-size: 14px;color: #666;">-->
                                <!--                                    个人简介：{{ item.introduction || '暂无信息' }}-->
                                <!--                                </div>-->
                                <div class="pf-msgbtn" @click.stop="goExpertShop(item)">进入店铺
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div v-else style="min-height:calc(100vh - 590px)"></div>
            <!--        高校专家 end-->
            <!--            分页 start-->
            <div style="height: 70px" v-if="professorList.length>4">
                <Page :total="professortotalNum" :page-size="professorparams.pageSize" show-elevator
                      style="margin-top: 20px;float: right;" @on-change="changesPage"/>
            </div>
            <!--            分页 end-->


            <!--            高校成果 start-->

            <div v-if="resultsList.length" style="margin-top:30px;background: #fff;border-radius: 8px;">
                <div style="height: 60px;line-height: 60px;padding: 0 20px;border-bottom: 1px dashed #E5E8ED;display: flex;">
                    <span style="color: #333;font-size: 24px;font-weight: bold;">高校成果</span>
                    <img src="~@/assets/image/patent/recommend.png" style="width:68px;height:24px;margin: 18px 10px"
                         alt=""/>
                </div>
                <div class="rl-list">
                    <div v-for="(item,index) in resultsList" :key="index" class="rl-item per-detail-hover">
                        <div style="height: 250px" @click="seeDetail2(item)">
                            <img v-if="item.mainImageUrl" :src="item.mainImageUrl"
                                 style="height: 250px;width: 250px" alt="">
                            <img v-else src="~@/assets/image/patent/patent-default.png"
                                 style="height: 250px;width: 250px" alt="">
                        </div>
                        <div style="height: 152px;">
                            <div style="height: 68px;line-height: 22px;padding: 12px 0;">
                              <span style="font-size: 16px;color: #333;font-weight: bold" class="liang_hang_sheng">
                                <div v-html="item.title" class="html-box"></div>
                              </span>
                            </div>
                            <div style="font-size: 14px;color:#999;overflow: hidden">
                                <div style="float: left"><span>{{ item.colleges }}</span></div>
                                <div style="float: right;color: rgb(255, 106, 0);">{{ item.mode }}</div>
                            </div>

                            <div style="font-size: 12px;margin-top: 10px">
                                <div class="consult" @click="openZoosUrl()">
                                    立即咨询
                                </div>
                                <div class="buybtn" @click.stop="seeDetail2(item)">查看详情
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height: 70px">
                    <Page :total="resultstotalNum" :page-size="resultsparams.pageSize" show-elevator
                          style="margin-top: 20px;float: right;" @on-change="changetPage"/>
                </div>

            </div>
            <!--            高校成果 end-->
        </div>


    </div>
</template>

<script>
    import {
        getshopbycollegeList,
        getshopDetail,
        getprofessorbycollegeList,
        getresultsbycollege,
        shopCommentList,
        sendComment
    } from "@/plugins/api/Shop";
    import {checkLogin} from "@/plugins/api/loginApi";
    import {successToast} from "@/plugins/tools/util";
    import bgimg from "@/assets/image/bgimg.png";

    export default {
        name: "experts",
        data() {
            return {
                shopId: '',
                shopparams: {
                    pageSize: 8,
                    pageNum: 1,
                },
                patentList: [],
                patenttotalNum: 0,
                professortotalNum: 0,
                shopDetal: [],
                professorList: [],
                professorparams: {
                    pageNum: 1,
                    pageSize: 4,
                    collegeName: ''
                },
                resultstotalNum: 0,
                resultsparams: {
                    pageNum: 1,
                    pageSize: 4,
                    colleges: ''
                },
                resultsList: [],
                commentparams: {
                    pageNum: 1,
                    pageSize: 8,
                },
                commentList: [],
                commentListTotal: 0,
                bgimg: bgimg
            }
        },
        watch: {
            $route: {
                immediate: true, // 一旦监听到路由的变化立即执行
                handler(to, from) {
                    if (JSON.stringify(to.params) !== '{}') {
                        if (!to.query.shopId) {
                            this.$router.push({name: 'list.vue'})
                        }
                        this.shopId = to.query.shopId
                        this.getshopbycollegeList()
                        this.getshopDetail()
                        this.shopCommentList()
                    }
                },
            }
        },
        methods: {
            goExpertShop(item) {
                let routeUrl = this.$router.resolve({
                    path: "/home/experts",//新页面地址
                    query: {shopId: item.id}//携带的参数
                });
                window.open(routeUrl.href, "_blank");
            },
            // 查看详情
            seeDetail(item) {
                let routeUrl = this.$router.resolve({
                    path: "/home/ResourceDetail",//新页面地址
                    query: {an: item.an}//携带的参数
                });
                window.open(routeUrl.href, "_blank");
            },
            shopCommentList() {
                shopCommentList({shopId: this.shopId, ...this.commentparams}).then(res => {
                    if (res.code === 0) {
                        this.commentList = res.result.list;
                        this.commentListTotal = res.result.total;
                    }
                })
            },
            // 成果
            seeDetail2(item) {
                let routeUrl = this.$router.resolve({
                    path: "/home/PatentSearchDetail",//新页面地址
                    query: {id: item.id}//携带的参数
                });
                window.open(routeUrl.href, "_blank");
            },
            //在线咨询
            openZoosUrl() {
                openZoosUrl('chatwin');
            },
            getshopbycollegeList() {
                getshopbycollegeList({
                    shopId: this.shopId,
                    ...this.shopparams
                }).then((res) => {
                    if (res.code === 0) {
                        this.patentList = res.result.list
                        this.patenttotalNum = res.result.total
                    }
                })
            },
            //高校详情
            getshopDetail() {
                getshopDetail({
                    shopId: this.shopId
                }).then((res) => {
                    if (res.code === 0 && res.result != null) {
                        this.shopDetal = res.result
                        //专家列表
                        this.professorparams.collegeName = res.result.name
                        this.resultsparams.colleges = res.result.name
                        this.getprofessorbycollegeList()
                        this.getresultsbycollege()
                    }
                })
            },
            // 翻页
            changefPage(index) {
                this.shopparams.pageNum = index;
                this.getshopbycollegeList();
            },
            // 翻页
            changesPage(index) {
                this.professorparams.pageNum = index;
                this.getprofessorbycollegeList();
            },
            // 翻页
            changetPage(index) {
                this.resultsparams.pageNum = index;
                this.getresultsbycollege();
            },
            //
            getprofessorbycollegeList() {
                getprofessorbycollegeList(this.professorparams).then(res => {
                    if (res.code === 0 && res.result != null) {
                        this.professorList = res.result.list
                        this.professortotalNum = res.result.total
                    }
                })
            },
            getresultsbycollege() {
                getresultsbycollege(this.resultsparams).then(res => {
                    if (res.code === 0 && res.result != null) {
                        this.resultsList = res.result.list

                        this.resultstotalNum = res.result.total
                    }
                })
            },
            //咨询弹窗
            returnRoom: function () {
                const state = checkLogin(this.$router)
                if (!state) {
                    return
                }

                this.$Modal.confirm({
                    draggable: true,
                    onOk: async () => {
                        this.sendComment()
                    },
                    render: (h) => {
                        return h('div',
                            [h('div', {
                                style: "margin-bottom:20px;vertical-align: middle;font-size: 16px;color: #17233d;font-weight: 700;",
                            }, '咨询内容'),
                                h('Input', {
                                    style: "width:100%;",
                                    props: {
                                        value: this.textareaVal,
                                        autofocus: true,
                                        placeholder: '请输入咨询内容',
                                        type: 'textarea',
                                        autosize: true
                                    },
                                    on: {
                                        input: (val) => {
                                            this.textareaVal = val;
                                        }
                                    }
                                })
                            ])
                    }
                })
            },
            //咨询列表
            shopCommentList() {
                shopCommentList({shopId: this.shopId, ...this.commentparams}).then(res => {
                    if (res.code === 0) {
                        this.commentList = res.result.list
                    }
                })
            },
            //发送咨询
            sendComment() {
                sendComment({
                    shopId: this.shopId,
                    reviewContent: this.textareaVal
                }).then(res => {
                    if (res.code === 0)
                        this.textareaVal = ""
                    successToast("咨询成功")
                })
            },
            //咨询上一页
            lastpre() {
                if (this.commentparams.pageNum > 1) {
                    this.commentparams.pageNum = this.commentparams.pageNum - 1
                    this.shopCommentList()
                }
            },
            //咨询下一页
            nextpre() {
                if (this.commentparams.pageNum * this.commentparams.pageSize < this.commentListTotal) {
                    this.commentparams.pageNum = this.commentparams.pageNum - 0 + 1
                    this.shopCommentList()
                }
            },

        }
    }
</script>

<style scoped>
    .per-detail-hover:hover {
        box-shadow: 6px 6px 20px -6px rgba(0, 0, 0, 0.3);
    }

    .pf-msgbtn {
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        width: 76px;
        height: 30px;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        border: 1px solid #1890FF;
        font-size: 14px;
        color: #1890ff;
        margin-top: auto;
    }

    .pf-msgbtn:hover {
        background-color: #1890ff;
        color: white !important;
        border: 1px solid #fff
    }

    .msgbtn {
        margin: 0 auto;
        height: 25px;
        width: 90px;
        text-align: center;
        line-height: 25px;
        border: 1px solid black;
        cursor: pointer;
    }

    .msgbtn:hover {
        background-color: #1890ff;
        color: white !important;
        border: 1px solid #fff
    }

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .liang_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }


    .zx_btn {
        width: 112px;
        text-align: center;
        line-height: 48px;
        height: 48px;
        background: #1890FF;
        border-radius: 4px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
    }

    .gd-list {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        justify-items: center;
        align-items: center;
        padding: 20px 0 40px;
        grid-row-gap: 30px;
    }

    .gd-item {
        height: 425px;
        background-color: white;
        width: 270px;
        padding: 10px 10px 20px;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        border: 1px solid #E5E8ED;
    }

    .consult {
        float: left;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        width: 120px;
        height: 36px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #1890FF;
        color: #1890FF;
    }

    .buybtn {
        float: right;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        width: 120px;
        height: 36px;
        background: #1890FF;
        color: #fff;
        border-radius: 4px;
    }

    .pf-list {
        display: grid;
        grid-template-columns: repeat(3, 33.33%);
        justify-items: center;
        align-items: center;
        padding: 20px 0 40px;
        grid-row-gap: 30px;
    }

    .pf-item {
        width: 380px;
        height: 170px;
        background: #F8F8F8;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        padding: 20px;
    }

    .rl-list {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        justify-items: center;
        align-items: center;
        padding: 20px 0 40px;
        grid-row-gap: 30px;
    }

    .rl-item {
        height: 412px;
        background-color: white;
        width: 270px;
        padding: 10px 10px 20px;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.15);
        border-radius: 8px;
        border: 1px solid #E5E8ED;
    }
</style>
