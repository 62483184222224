<template>
    <!--商品列表-->
    <div :style="{backgroundImage:'url('+bgimg+')','background-size':'100% auto'}">
        <div style="color: #999;font-size: 14px;line-height: 50px;width: 1200px;margin:0 auto;">
            当前位置  <span style="margin: 0 10px">/</span>  专利超市  <span style="margin: 0 10px">/</span> <span style="color: #1890FF">专利列表</span>
        </div>
        <div class="center">
            <!--            顶部筛选项 start-->
            <div style="padding: 8px 24px 17px;background: #fff;border-radius: 8px;">
                <div class="service_div">
                    <div style="margin: 0 auto;height: 100%;display: flex;position: relative">
                        <span style="margin-right: 70px;color:#333;color: #999;">相关分类:</span>
                        <div style="width: 1000px">
                            <div @click="selectClassifyOne(-1)"
                                 :class="{'select_span':classifyOneChoice == -1}"
                                 style="float: left;margin-right: 20px;padding: 0 10px;cursor: pointer"
                            >全部分类
                            </div>
                            <div style="float: left;width: 880px">
            <span v-for="(item,index) in classify" :key="index"
                  @click="selectClassifyOne(index)"
                  :class="{'select_span':classifyOneChoice == index}"
                  v-show="index < 9 || isallclassify"
            >{{ item.name }}</span>
                            </div>
                        </div>
                        <div @click="changeclassifyshow()" v-show="classify.length >= 9 && !isallclassify "
                             style="position: absolute;right: 0px;cursor: pointer">展开
                            <Icon type="ios-arrow-forward" />
                        </div>
                        <div @click="changeclassifyshow()" v-show="classify.length >= 9 && isallclassify"
                             style="position: absolute;right: 0px;cursor: pointer">收起
                            <Icon type="ios-arrow-down" />
                        </div>
                    </div>
                </div>
                <!--            二级分类-->
                <div v-show="classifyTwo.length" class="service_div">
                    <div
                            style="width: 1180px;margin: 0 auto;height: 100%;display: flex;position: relative">
                        <span style="margin-right: 70px;color:#333;color: #999;">二级分类:</span>
                        <div style="width: 1000px">
                            <div @click="selectClassifyTwo(-1)"
                                 :class="{'select_span':classifyTwoChoice == -1}"
                                 style="float: left;margin-right: 20px;padding: 0 10px;cursor: pointer"
                            >全部分类
                            </div>
                            <div style="float: left;width: 880px">
              <span v-for="(item,index) in classifyTwo" :key="index"
                    @click="selectClassifyTwo(index)"
                    :class="{'select_span':classifyTwoChoice == index}"
                    v-show="index < 9 || isallclassify"
              >{{ item.name }}</span>
                            </div>
                        </div>
                        <div @click="changeclassifyshow()" v-show="classifyTwo.length >= 9 && !isallclassify "
                             style="position: absolute;right: 10px;cursor: pointer">展开
                            <Icon type="ios-arrow-forward" />
                        </div>
                        <div @click="changeclassifyshow()" v-show="classifyTwo.length >= 9 && isallclassify"
                             style="position: absolute;right: 10px;cursor: pointer">收起
                            <Icon type="ios-arrow-down" />
                        </div>
                    </div>
                </div>
                <!--            三级分类-->
                <div v-show="classifyThree.length" class="service_div">
                    <div
                            style="width: 1180px;margin: 0 auto;height: 100%;display: flex;position: relative">
                        <span style="margin-right: 70px;color:#333;color: #999;">三级分类:</span>
                        <div style="width: 1000px">
                            <div @click="selectClassifyThree(-1)"
                                 :class="{'select_span':classifyThreeChoice == -1}"
                                 style="float: left;margin-right: 20px;padding: 0 10px;cursor: pointer"
                            >全部分类
                            </div>
                            <div style="float: left;width: 880px">
               <span v-for="(item,index) in classifyThree" :key="index"
                     @click="selectClassifyThree(index)"
                     :class="{'select_span':classifyThreeChoice == index}"
                     v-show="index < 9 || isallclassify"
               >{{ item.name }}</span>
                            </div>
                        </div>
                        <div @click="changeclassifyshow()" v-show="classifyThree.length >= 9 && !isallclassify "
                             style="position: absolute;right: 10px;cursor: pointer">展开
                            <Icon type="ios-arrow-forward" />
                        </div>
                        <div @click="changeclassifyshow()" v-show="classifyThree.length >= 9 && isallclassify"
                             style="position: absolute;right: 10px;cursor: pointer">收起
                            <Icon type="ios-arrow-down" />
                        </div>
                    </div>
                </div>
                <div class="service_div">
                    <div style="margin: 0 auto;height: 100%;display: flex;position: relative">
                        <span style="margin-right: 70px;color:#333;color: #999;">专利类型:</span> <span
                            v-for="(item,index) in patentType"
                            :key="index"
                            @click="selectPatentType(index)"
                            :class="{'select_span':patentTypeChoice == index}">{{ item.name }}</span>
                    </div>
                </div>
                <div class="service_div">
                    <div style="margin: 0 auto;height: 100%;display: flex;position: relative">
                        <span style="margin-right: 70px;color:#333;color: #999;">交易类型:</span> <span
                            v-for="(item,index) in serviceType"
                            :key="index"
                            @click="selectServiceType(index)"
                            :class="{'select_span':serviceTypeChoice == index}">{{ item.name }}</span>
                    </div>
                </div>
            </div>
            <!--            顶部筛选项 end-->
            <!--            专利列表 start -->
            <div style="background: #fff;border-radius: 8px;">
                <!--            排序 start-->
                <div id="order">
                    <span style="padding-left: 10px;" @click="chooseMoRen()" :class="{'active' : params.sortField==''}">综合</span>
                    <span @click="choosePRICE()"
                          :class="[{'active' : params.sortField=='PRICE'},{'activeASC' : params.sortField=='PRICE' && params.sortOrder=='ASC'}]">价格
                        <Icon type="md-arrow-dropup" />
                        <Icon type="md-arrow-dropdown" />
                    </span>
                    <span @click="chooseATTENTION()"
                          :class="[{'active' : params.sortField=='ATTENTION'},{'activeASC' : params.sortField=='ATTENTION' && params.sortOrder=='ASC'}]">关注度
                        <Icon type="md-arrow-dropup" />
                        <Icon type="md-arrow-dropdown" />
                    </span>
                    <span @click="chooseUPEDTIME()"
                          :class="[{'active' : params.sortField=='UPEDTIME'},{'activeASC' : params.sortField=='UPEDTIME' && params.sortOrder=='ASC'}]">上架时间
                        <Icon type="md-arrow-dropup" />
                        <Icon type="md-arrow-dropdown" />
                    </span>
                </div>
                <!--            排序 end-->
                <div class="gd-list">
                    <div v-for="(item,index) in goodsList" :key="index" class="good-item good_choice">
                        <div style="height: 250px;cursor: pointer;" @click="checkGood(item.id)">
                            <img style="width: 250px;height: 203px;object-fit: cover" :src="item.mainImageUrl" />
<!--                            <img v-if="item.patentType == '发明专利'" style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/famingzhuanli.jpg" />-->
<!--                            <img v-else-if="item.patentType == '实用新型'" style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/shiyongxinxing.jpg" />-->
<!--                            <img v-else style="width: 250px;height: 250px;" src="https://cnipy-public.oss-cn-shanghai.aliyuncs.com/zhuanlipeitu/waiguanzhuanli.jpg" />-->
                        </div>
                        <div style="height: 50px;line-height: 50px;color: #FF6A00">
                            <span style="font-size: 16px">¥ </span>
                            <span style="font-size: 24px">{{ item.price }}</span>
                        </div>
                        <div class="good-name yi_hang_sheng"  @click="checkGood(item.id)">
                                {{ item.name }}
                        </div>
                        <div class="good-label">
                            <div style="float: left"><span>{{ item.shopName }}</span></div>
                            <div style="float: right;">{{ item.patentType }}
                            </div>
                        </div>

                        <div class="good-btn">
                            <div class="consult" @click="openZoosUrl()">
                                立即咨询
                            </div>
                            <div class="buybtn" @click="jumpAction(1,item.id)">立即购买
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--            专利列表 end -->
            <!--            分页-->
            <div STYLE="overflow: hidden">
                <Page :total="totalNum" :page-size="params.pageSize" show-elevator
                      style="margin-top: 30px;float: right;margin-bottom: 30px" @on-change="changePage"/>
            </div>
        </div>

    </div>
</template>

<script>
    import bgimg from "@/assets/image/bgimg.png";
    import {
        getClassify,
        getGoodsList,
        goodsRest
    } from "@/plugins/api/Good";
    import {errorToast} from "../../plugins/tools/util";

    export default {
        name: "goodList",
        data() {
            return {
                notFirst: true,
                patentType: [
                    {type: '', name: '全部分类'},
                    {type: 'INVENTION', name: '发明专利'},
                    {type: 'UTILITY', name: '实用新型'},
                    {type: 'APPEARANCE', name: '外观设计'}
                ],

                serviceType: [
                    {type: '', name: '全部分类'},
                    {type: 'TRANSFER', name: '转让'},
                    {type: 'LICENSE', name: '许可'}
                ],
                params: {
                    pageSize: 8,
                    pageNum: 1,
                    code: '',
                    mode: '', //交易类型(TRANSFER:转让;LICENSE:许可)
                    patentType: '', //专利类型(INVENTION:发明专利;UTILITY:实用新型;APPEARANCE:外观设计)
                    sortField: '', //排序字段（PRICE:价格;ATTENTION:关注度;UPEDTIME:上架时间）
                    sortOrder: '',  //排序方式（DESC:降序;ASC:升序）
                },
                totalNum: 0,
                goodsList: [],
                classify: [],
                classifyTwo: [],
                classifyThree: [],
                classifyOneChoice: -1,
                classifyTwoChoice: -1,
                classifyThreeChoice: -1,
                patentTypeChoice: 0,
                serviceTypeChoice: 0,
                isallclassify: false,//相关分类是否展开
                bgimg: bgimg,
            }
        },
        mounted() {
            this.getClassify()
            this.getGoodsList()
        },
        methods: {
            //展开收起
            changeclassifyshow() {
                this.isallclassify = !this.isallclassify
            },
            //在线咨询
            openZoosUrl() {
                openZoosUrl('chatwin');
            },
            //商品列表分类
            getClassify() {
                getClassify({type: 'PATENT'}).then(res => {
                    if (res.code === 0) {
                        this.classify = res.result
                        if (this.$route.query.code) {
                            for (let i = 0; i < this.classify.length; i++) {
                                if (this.classify[i].code == this.$route.query.code) {
                                    this.classifyOneChoice = i
                                    this.getGoodsList();
                                    break;
                                }
                            }
                        }
                    }
                })
            },
            //商品列表
            getGoodsList() {
                let params = {}
                for (let i in this.params) {
                    if (this.params[i]) {
                        params[i] = this.params[i]
                    }
                }
                if (this.$route.query.code && this.notFirst == true) {
                    params.code = this.$route.query.code
                }
                getGoodsList(params).then(res => {
                    if (res.code === 0) {
                        this.goodsList = res.result.list
                        this.totalNum = res.result.total
                    }
                })
            },
            // 翻页
            changePage(index) {
                this.params.pageNum = index;
                this.getGoodsList();
            },
            //相关分类index-1
            selectClassifyOne(index) {
                this.notFirst = false;
                this.classifyOneChoice = index
                this.classifyTwoChoice = -1;
                this.classifyThreeChoice = -1;
                this.classifyTwo = [];
                this.classifyThree = [];
                this.params.code = ''
                if (index >= 0) {
                    console.log(this.classify[index])
                    this.params.code = this.classify[index].code
                    if (this.classify[index].children) {
                        this.classifyTwo = this.classify[index].children
                    }

                }
                this.getGoodsList()
            },
            //相关分类index-2
            selectClassifyTwo(index) {
                this.classifyTwoChoice = index
                this.classifyThreeChoice = -1;
                this.classifyThree = [];
                this.params.code = ''
                if (index >= 0) {
                    this.params.code = this.classifyTwo[index].code
                    this.classifyThree = this.classifyTwo[index].children
                }
                this.getGoodsList()
            },
            //相关分类index-3
            selectClassifyThree(index) {
                this.classifyThreeChoice = index
                this.params.code = ''
                if (index >= 0) {
                    this.params.code = this.classifyThree[index].code
                }
                this.getGoodsList()
            },
            //专利index
            selectPatentType(index) {
                this.patentTypeChoice = index
                if (index > 0) {
                    this.params.patentType = this.patentType[index].type
                } else {
                    this.params.patentType = ''
                }
                this.getGoodsList()
            },
            //交易index
            selectServiceType(index) {
                this.serviceTypeChoice = index
                if (index > 0) {
                    this.params.mode = this.serviceType[index].type
                } else {
                    this.params.mode = ''
                }
                this.getGoodsList()
            },
            //跳转商品详情页
            checkGood(id) {
                this.$router.push({
                    name: 'good',
                    query: {
                        goodId: id
                    }
                })
            },
            //默认排序
            chooseMoRen() {
                this.params.sortField = '';
                this.params.sortOrder = '';
                this.getGoodsList();
            },
            //价格排序
            //排序字段（PRICE:价格;ATTENTION:关注度;UPEDTIME:上架时间）
            //排序方式（DESC:降序;ASC:升序）
            choosePRICE() {
                if (this.params.sortField == 'PRICE') {
                    this.params.sortOrder = this.params.sortOrder == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    this.params.sortField = 'PRICE';
                    this.params.sortOrder = 'DESC'
                }

                this.getGoodsList();
            },
            chooseUPEDTIME() {
                if (this.params.sortField == 'UPEDTIME') {
                    this.params.sortOrder = this.params.sortOrder == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    this.params.sortField = 'UPEDTIME';
                    this.params.sortOrder = 'DESC'
                }
                this.getGoodsList();
            },
            chooseATTENTION() {
                if (this.params.sortField == 'ATTENTION') {
                    this.params.sortOrder = this.params.sortOrder == 'ASC' ? 'DESC' : 'ASC';
                } else {
                    this.params.sortField = 'ATTENTION';
                    this.params.sortOrder = 'DESC'
                }
                this.getGoodsList();
            },
            // 立即购买
            async jumpAction(index, goodId) {
                let json = await goodsRest({goodsId: goodId, num: 1})
                if (json.code == 0) {
                    if (json.result == true) {
                        this.$router.push({
                            name: 'good',
                            query: {
                                goodId: goodId,
                                action: index
                            }
                        })
                    } else {
                        errorToast(json.message)
                    }
                } else {
                    errorToast(json.message)
                }

            }
        }
    }
</script>


<style scoped lang="scss">
    /deep/ .ivu-page-item-active {
        background-color: #2d8cf0;
        color: white !important;
        border: none;
    }

    /deep/ .ivu-page-item-active a {
        color: white !important;
    }

    .iconfont {
        font-size: 13px;
        vertical-align: -0.05rem !important;
    }

    .zl_header .per_header:nth-child(3), .zl_header .per_header:nth-child(6) {
        border-right: 1px solid rgb(232, 232, 232) !important;
    }

    .left_desc {
        padding-left: 17px;

        & span {
            line-height: 30px;
        }
    }

    #order {
        margin-top: 10px;
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        border-radius: 8px 8px 0px 0px;
        background: #F5F5F5;
    }

    #order > span {
        display: inline-block;
        cursor: pointer;
        width: 90px;
        text-align: center;
        color: #999;
        position: relative;
    }

    .per_header {
        padding: 20px;
    }

    .zl_header .per_header:nth-child(4), .zl_header .per_header:nth-child(5), .zl_header .per_header:nth-child(6) {
        margin-top: 4px !important;
    }

    .service_div {
        font-size: 14px;
        line-height: 32px;
        margin-top: 9px;

        & span {
            display: inline-block;
            height: 32px;
            margin-right: 20px;
            cursor: pointer;
            color: #333;
            padding: 0 10px;
        }
    }

    .rmzl_content_real {
        padding: 20px 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        text-align: center;
        flex-wrap: wrap;
        align-items: center;

    }

    .dpyx {
        & span {
            margin: 0 2px;
        }
    }

    .san_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .yi_hang_sheng {
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }

    .liang_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .si_hang_sheng {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }

    .rmzl_title_price .desc {
        text-align: left;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;;
        overflow: hidden;
    }

    .rmzl_image {
        width: 150px;
        height: 180px;
        float: left;
    }

    .rmzl_title_price {
        width: 228px;
        height: 180px;
        line-height: 1;
        float: left;
        box-sizing: border-box;
        padding: 20px 15px;
    }

    .div_center {
        width: 100%;
    }

    .center {
        width: 1200px;
        margin: 0 auto;
    }

    .select_span {
        text-align: center;
        background: #1890FF;
        border-radius: 4px;
        color: #fff !important;
    }

    #order i:first-child{
        position: absolute;
        top: 10px;
    }

    #order i:last-child{
        position: absolute;
        top: 17px;
    }

    .active {
        color: #1890FF!important;
        background: #FFFFFF;
        border-radius: 8px 8px 0px 0px;
    }

    .active i:first-child {
        color: #999;

    }

    .active i:last-child {
        color: #1890FF;
    }

    .activeASC i:first-child {
        color: #1890FF;
    }

    .activeASC i:last-child {
        color: #999;
    }

    .consult {
        float: left;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        width: 120px;
        height: 36px;
        background: #FFFFFF;
        border-radius: 4px;
        border: 1px solid #1890FF;
        color: #1890FF;
    }

    .buybtn {
        float: right;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        width: 120px;
        height: 36px;
        background: #1890FF;
        color: #fff;
        border-radius: 4px;
    }

    .good-item {
        width: 270px;
        height: 430px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #E5E8ED;
        padding: 10px;
    }

    .good_choice:hover {
        box-shadow: 6px 6px 20px -6px rgba(0, 0, 0, .4);
    }

    .good-name {
        font-size: 16px;
        color: #333333;
        height: 22px;
        font-weight: bold;
        width: 250px;
        cursor: pointer;
    }

    .good-label {
        height: 40px;
        line-height: 40px;
        font-size: 14px;
        overflow: hidden;
        color: #999;
        font-weight: 400;
    }

    .gd-list {
        display: grid;
        grid-template-columns: repeat(4, 25%);
        justify-items: center;
        align-items: center;
        padding: 20px 0 40px;
        grid-row-gap: 30px;
    }
</style>
